@use 'uswds-core' as *;

@media (min-width: 64em) {
  .usa-logo {
    margin: 0;
  }

  .usa-header--basic .usa-nav {
    padding: 0;
  }

  .usa-header--basic .usa-nav-container {
    align-items: center;
  }
}

.usa-menu-btn {
  background-color: color('primary-darker');
}

.search-alert-banner {
  background-color: color('gold-5v');
  font-size: .88rem;
  padding-block: .2rem;
}

.usa-nav__primary button {
  padding: 1rem;
  padding-left: .5;
  
  span {
    padding-right: .5rem;
  }
}

.usa-header {
  background-color: #f3f9fe;

  .usa-logo em {
    &::before {
      content: url('../img/fac-logo-full.svg');
    }
  }
}

.usa-logo-link,
.usa-logo-link:active,
.usa-logo-link:visited {
  color: color('primary-darker');
  text-decoration: none;
}

.usa-nav-container-links {
  background: linear-gradient(to right, #DFF0F6 0%, rgb(223 240 246 / 0%) 87.5%);
}

.usa-nav-container-links.is-visible {
  background-color: #f3f9fe;
}

.sign-in,
.sign-in:active,
.sign-in:visited {
  color: color('primary-darker');
  font-size: 0.88rem;
  font-weight: bold;
  line-height: 1.1rem;
  margin: 0;
  display: inline-block;
  text-decoration: none;

  span {
    font-weight: normal;
    display: block;
  }

  svg {
    transform: rotate(270deg);
    transform-box: fill-box;
    transform-origin: center;
  }
}

.sign-out {
  padding: 1.4rem;
  background-color: color('primary-darker');

  button {
    color: #fff;
    display: flex;
    flex-flow: column wrap;
    height: 2em;
    text-decoration: none;

    &:hover {
      color: #fff;
    }

    span {
      font-size: size('body', 'xs');
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 10rem;
    }

    svg {
      font-size: size('body', 9);
      height: 2em;
      margin-right: 0.4em;
    }
  }
}
