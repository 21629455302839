@use 'uswds-core' as *;

.usa-form {
  padding-bottom: 2rem;
}

.question {
  margin-bottom: 1.5rem;

  .usa-legend {
    font-weight: 700;
  }
}

.usa-legend abbr[title='required'] {
  position: absolute;
  padding-left: 0.2rem;
}

.usa-radio .usa-error-message {
  display: none;
}

.usa-radio--error {
  .usa-error-message {
    display: block;
  }

  .usa-radio__input:checked + [class*='__label']::before {
    background-color: #b50909;
    box-shadow: 0 0 0 2px #b50909, inset 0 0 0 2px #fff;
  }
}

a.usa-button {
  text-decoration: none;
}

ul.usa-error-message {
  display: none;
}

.usa-form-group--error ul.usa-error-message {
  display: block;
}

.audit-submission-access {
  form {
    max-width: 100%;
  }

  .grid-container {
    padding-left: 0;
  }
}

#check-eligibility {
  .uei-explanation + .usa-form-group,
  .usa-form-group + .uei-explanation {
    margin-top: 1.5rem;
  }

  .usa-search {
    input,
    button {
      height: 2.5rem;
    }

    button {
      padding: 0 1.5rem;
    }
  }

  .usa-search__submit-text {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    img {
      display: block !important;
      margin-right: 3px;
    }
  }
}

.auditee-information {
  background-color: #f0f0f0;
  max-width: 100%;

  .grid-row {
    margin-left: auto;
    margin-right: auto;
    max-width: 64em;
  }

  form {
    background-color: #fff;
    margin: 2rem;
    padding: 2rem;
  }

  .ueid-explanation {
    margin: 2rem;
    padding: 2rem 0;
  }

  .ueid-explanation > h2 {
    font-family: 'Source Sans Pro Web', 'Helvetica Neue', Helvetica, Roboto,
      Arial, sans-serif;
  }

  ul.usa-error-message {
    display: none;
  }

  .usa-form-group--error ul.usa-error-message {
    display: block;
  }

  .validate-uei {
    margin-top: 0;
  }

  .validate-uei button {
    margin-top: 1em;
  }

  #uei-success {
    margin-top: 0.5em;

    & svg {
      fill: #538200;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.auditee-information dd,
.uei-search-result dd {
  font-weight: bold;
  margin-left: 0;
  margin-bottom: 1rem;
}

.auditee-information dl,
.uei-search-result dl {
  margin-bottom: 2rem;
}

.uei-search-result {
  img {
    display: none;
    margin: auto;
  }

  &.loading {
    button {
      display: none;
    }

    img {
      display: block;
    }
  }
}

#no-uei-warning {
  color: color('secondary');

  h2 {
    font-size: size('body', 8);
  }

  .usa-icon {
    top: 0.1em;
  }
}

.delete-contact {
  margin: 0 !important;
  margin-top: auto !important;
  margin-bottom: 0.5rem !important;
  padding: 0 !important;

  .usa-icon {
    fill: #d83933;
    height: 1.5em;
    width: 1.5em;
    vertical-align: middle;
  }
}

.add-contact {
  margin-top: 2.5rem;
}

.additional_contacts {
  margin-top: 2.5rem;
}

#grant-access {
  .usa-legend--large {
    max-width: 80ch;
  }

  .usa-summary-box {
    margin: 2em 0;

    .usa-list li {
      max-width: 100%;
    }
  }

  .required-explanation {
    margin-bottom: 3em;
  }

  .usa-fieldset .question:first-child {
    margin-top: 3em;
  }

  .question {
    border-bottom: 1px solid color('base-light');
    margin-top: 2em;
    padding-bottom: 3em;

    .usa-legend {
      font-size: size('body', 'lg');
    }

    .usa-form-group {
      margin-top: 0;
    }
  }

  #email-notice {
    margin: 3em 0 0;
  }

  .usa-button-group__item .usa-button {
    margin-top: 1em;
  }

  legend + .grid-container {
    margin-top: 1.5em;
  }

  .grid-row + .grid-row {
    margin-top: 1em;
  }
}

.audit-submission-access .tablet\:grid-col-fill {
  max-width: 38ch;
}

/* stylelint-disable */
#auditee_name[disabled] {
  cursor: not-allowed;
}

/* stylelint-enable */

@media (min-width: 64em) {
  .sticky-nav {
    align-self: flex-start;
    position: sticky;
    top: 0;

    .usa-sidenav a:not([href]),
    .usa-sidenav a:not([href]):hover,
    .usa-sidenav a:not([href]):active,
    .usa-sidenav a:not([href]):visited {
      color: #72777a;
      cursor: not-allowed;
    }

    a:not(.usa-button) {
      display: flex;
      width: 100%;

      svg {
        align-self: center;
        fill: color('base');
        margin-left: auto;
      }
    }

    .usa-sidenav .usa-current {
      font-weight: bold;
    }
  }
}

.audit-metadata {
  padding-bottom: units(2);
  padding-top: units(2);
  background-color: #f0f0f0;

  dt {
    font-weight: bold;
    display: inline;

    &::after {
      content: ':';
    }
  }

  dd {
    margin-inline-start: 0;
    display: inline;
  }

  .grid-row {
    margin-bottom: units(1);
    margin-top: units(1);
  }
}

.sf-sac {
  fieldset:not(.radio) {
    margin-bottom: units(4);

    fieldset {
      margin-top: units(4);

      legend {
        font-size: size('sans', 'lg');
      }

      .usa-form-group {
        margin-top: units(2);
      }

      fieldset {
        &.radio,
        &#audit-firm-organization-address {
          legend {
            font-size: size('sans', 'md');
          }

          & + .usa-form-group {
            margin-top: units(4);
          }
        }

        label:not(.usa-radio__label) {
          color: color('gray-cool-50');
        }
      }
    }

    .usa-input--small {
      display: inline;
      margin-left: 2rem;
    }

    .usa-input--small__label {
      display: inline;
    }
  }
}

.federal-awards {
  margin-top: 2rem;

  h1 {
    font-size: size('body', 'xl');
    margin: 0;
  }

  .usa-button {
    margin-top: 0;
  }

  .usa-process-list__item.complete::before {
    border-color: #00a91c;

    // TODO: Fix this path & possibly move the image over from FAC-Frontend
    // content: url('../../assets/img/usa-icons/green-check.svg');
  }

  [type='submit'] {
    margin-top: 1rem;
  }
}

.cross-validation-loader {
  text-align: center;
}

.user-management-td {
  text-align: center;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
