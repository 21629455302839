@use '_header';

.usa-hero {
  background-image: none;
  font-size: 1rem;
  line-height: 1.6;
}

.usa-hero > .grid-container {
  padding: 0;
}

.usa-hero__heading {
    font-size: 2.25rem;
    margin-top: 0;
    line-height: 1.2;
    font-weight: 700;
}

.usa-hero__heading, .usa-hero p {
    color: #1b1b1b;
    font-family: "Public Sans Web",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.usa-hero .usa-hero__heading, .usa-hero p, .usa-hero .usa-button-group {
    margin-bottom: 2.2rem;
}

.usa-hero .intro-text {
    font-size: 1.25rem;
}

.usa-date-picker__button {
  @extend %usa-date-picker__button !optional;
}

.usa-hero .usa-card {
  text-align:center;
}

.usa-hero .usa-card .usa-card__container {
  padding: 2rem 0;
}

.usa-hero .usa-card__container {
  margin-top: 2rem;
}

.usa-hero .usa-card p, .usa-card__body, .usa-card__body:last-child {
  padding-block-end: 0;
  margin-block-end: 0;
}

.resources .usa-card {
  min-height: 96px;
}

.resources .usa-card .usa-card__container .usa-card__header {
  min-height: 96px;
}

.home-image.submission {
  background: url('../img/home/submit_an_audit.png');
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgb(26 68 128 / 70%);
}

.home-image.searching {
  background: url('../img/home/search_for_audits.png');
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgb(26 68 128 / 70%);
}

.home-image {
  .usa-link:link, .usa-link:hover, .usa-link:active {
    text-decoration: none;
  }

  .usa-link:visited {
    text-decoration: none;
    color: white;
  }
}
